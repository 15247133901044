import React from 'react';
import './App.scss';
import DiagramOptionsPanelContainer from "./components/diagramOptionsPanel/diagramOptionsPanelContainer";
import {
    BrowserRouter as Router,
    Switch,
    Route,
} from 'react-router-dom';
import DiagramDisplayPanelContainer from "./components/diagramDisplayPanel/diagramDisplayPanelContainer";
import FlagsProvider from './context/FlagsProvider';

const App: React.FC = () => {
  return (
      <FlagsProvider defaults={{
          loginFeatureEnabled: false,
      }}>
          <Router>
              <Switch>
                  <Route path="/diagram/:id">
                      <DiagramDisplayPanelContainer/>
                  </Route>
                  <Route path="/:repo?">
                      <DiagramOptionsPanelContainer ignoredDirectories="" repoUrl="" directories=""/>
                  </Route>
              </Switch>
           </Router>
      </FlagsProvider>
  );
};

export default App;
