import {Button, Drawer} from "antd";
import Iframe from "react-iframe";
import React, {useState} from "react";

import { Icon } from 'antd';
import "./feedback.css";

interface FeedbackProperties {
	url: string
}

export const Feedback:React.FC<FeedbackProperties> = (props) => {
	const [isVisibleFeedbackForm, setIsVisibleFeedbackForm] = useState(false);
	return (
		<>
			<div
				style={{
						position:"absolute",
						top:"50%",
						right: "0.6em",
						zIndex:10,
						height:"45px",
						width:"30px",
						padding: "0px",
					    transform: "rotate(90deg)"
					}}
			    onClick={() => {setIsVisibleFeedbackForm(!isVisibleFeedbackForm)}}
				title="feedback"
			>
				<Button type="primary"
				        ghost>
					<span>Feedback</span>
					<Icon type="mail" theme="twoTone" />
				</Button>

			</div>
				<Drawer
					width="100%"
					title="Leave Feedback"
					placement="right"
					closable={true}
					onClose={() => {setIsVisibleFeedbackForm(false)}}
					visible={isVisibleFeedbackForm}
				>
					<Iframe url={props.url+"?value="+Math.random()}
					        width="100%"
					        height="480px"
					        overflow={"scroll"}
					        id="feedbackSurvey"
					        position="relative"
							loading="lazy"/>
				</Drawer>
		</>
	)
};

export default Feedback;