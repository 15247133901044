import React, {useRef, useState} from 'react';
import {Switch, Input, Collapse, message, Icon} from 'antd';

import './diagramOptionsPanel.scss';
import { Diagram } from '../../models/diagram';
import Feedback from "../feedback/feedback";
import {useLocalStorage} from "react-use";
import {RecentProject} from "../../models/recentProject";
import {FeatureFlags} from "../../models/featureFlags";
let goBoard = require('../../images/go-board.jpeg');

interface DiagramOptionsPanelProps {
    repoUrl: string;
    ignoredDirectories: string;
    directories: string;
    isIncludedFieldsShown?: boolean;
    isIncludedMethodsShown?: boolean;
    onContinue(diagram: Diagram): void;
    flags: FeatureFlags;
}


const DiagramOptionsPanel:React.FC<DiagramOptionsPanelProps> =  (props) => {
    const { Search } = Input;
    const { Panel } = Collapse;
    const [projects] = useLocalStorage<RecentProject[]>('dumels-recents', []);
    const [isInactiveIgnoredDirectories, setIsInactiveIgnoredDirectories] = useState(true);
    const repoDirectoriesRef = useRef<Input>(null);
    const repoIgnoredDirectoriesRef = useRef<Input>(null);
    const repoIgnoredDirectoriesToggleRef = useRef<Switch>(null);
    const repoIgnoreFieldsToggleRef = useRef<Switch>(null);
    const repoIgnoreMethodsToggleRef = useRef<Switch>(null);
    const [isIgnoreFieldsChecked, setIsIgnoreFieldsChecked] = useState<boolean>(props.isIncludedFieldsShown! && true);
    const [isIgnoreMethodsChecked, setIsIgnoreMethodsChecked] = useState<boolean>(props.isIncludedMethodsShown! && true);
    const onSearchHandler = (value:string) => {
        if (!value) {
            message.error({
                content: 'Repository URL cannot be empty',
                duration: 5,
                key: 1,
            });

            return;
        }
        const directoriesString = repoDirectoriesRef.current!['state']['value'] || props.directories;
        const directoriesList = directoriesString.split(",");
        const directoriesPathList = directoriesList.map((e: string, _: number) => {
            if (e) {
                return "/" + e;
            }
            return "";
        });
        const isFieldsChecked = repoIgnoreFieldsToggleRef.current!['rcSwitch']['state']['checked'];
        const isMethodsChecked = repoIgnoreMethodsToggleRef.current!['rcSwitch']['state']['checked'];
        const isIgnoredDirectoriesChecked = repoIgnoredDirectoriesToggleRef.current!['rcSwitch']['state']['checked'];
        let ignoredDirectories = [""];
        if (isIgnoredDirectoriesChecked) {
            const ignoredDirectoriesRaw = repoIgnoredDirectoriesRef.current!.state.value;
            if (ignoredDirectoriesRaw) {
                ignoredDirectories = repoIgnoredDirectoriesRef.current!.state.value.split(',');
                ignoredDirectories = ignoredDirectories.map((e,i)=>{
                    return "/"+e.trim();
                });
            }
        }
        props.onContinue({
            repo: value,
            directories: directoriesPathList,
            ignoredDirectories: ignoredDirectories,
            renderingOptions: {
                fields: isFieldsChecked,
                methods: isMethodsChecked,
            }
        } as Diagram);
    };


    return (
        <>
        <div className="optionsHeaderContainer">
            <h1 className="optionsHeader" data-testid="pageHeading">Dumels</h1>
            { props.flags.loginFeatureEnabled &&
                <a href="/login" className="loginButton" title="login"><Icon style={{ fontSize: 22, color: '#1890ff' }} type="login" /></a>
            }
        </div>
        <div className="gud-diag-options_search_bar_section">
            <label className='gud-diag-options_search_label' htmlFor="repoUrl">Repository URL</label>
            <Search
                className='gud-diag-options_search_bar_button'
                id="repoUrl"
                placeholder="Ex: https://github.com/jfeliu007/goplantuml"
                enterButton="Generate"
                size="large"
                onSearch={onSearchHandler}
                data-testid="searchInput"
                defaultValue={props.repoUrl || ""}
            />
        </div>
        <div className='gud-diag-cols-box'>
            <div className='gud-diag-options_rendering_options'>
                <Collapse accordion className='gud-diag-options_rendering_options_collapsible' defaultActiveKey={['1']}>
                    <Panel header="Rendering Options" key="1">
                        <div className='gud-diag-options_rendering_options_content'>
                            <div className='gud-diag-options_rendering_options_directories'>
                                <label htmlFor="directoriesDefinition">Directories to Include (defaulted to project's root)</label>
                                <Input
                                    ref={repoDirectoriesRef}
                                    className='gud-diag-options_rendering_options_directories_input'
                                    type="text"
                                    id="directoriesDefinition"
                                    data-testid = "directoriesInput"
                                    placeholder='comma separated list of folders'/>
                            </div>

                            <div className='gud-diag-options_rendering_options_directories'>
                                <div>
                                    <Switch ref={repoIgnoredDirectoriesToggleRef} className='gud-diag-options_rendering_options_toggle' onChange={()=>setIsInactiveIgnoredDirectories(!isInactiveIgnoredDirectories)} />
                                    <label htmlFor="ignoredDirectoriesDefinition">Ignore Directories</label>
                                </div>
                                <Input
                                    ref={repoIgnoredDirectoriesRef}
                                    className='gud-diag-options_rendering_options_subdirectories_input'
                                    type="text"
                                    id="ignoredDirectoriesDefinition"
                                    placeholder='comma separated list of folders'
                                    data-testid = "ignoredDirectoriesInput"
                                    defaultValue={props.ignoredDirectories || ""}
                                    disabled={isInactiveIgnoredDirectories}
                                />
                            </div>

                            <div>
                                <Switch ref={repoIgnoreFieldsToggleRef} className='gud-diag-options_rendering_options_toggle' checked={isIgnoreFieldsChecked} onChange={()=>{setIsIgnoreFieldsChecked(!isIgnoreFieldsChecked)}} />
                                <span>Include Fields</span>
                            </div>

                            <div>
                                <Switch ref={repoIgnoreMethodsToggleRef} className='gud-diag-options_rendering_options_toggle' checked={isIgnoreMethodsChecked} onChange={()=>{setIsIgnoreMethodsChecked(!isIgnoreMethodsChecked)}} />
                                <span>Include Methods</span>
                            </div>
                        </div>
                    </Panel>
                    <Panel header="Recent Projects" key="2">
                        <div className="reposList">
                        {
                            projects?.map((element) => {
                                return <div className="recentProject">
                                         <Icon className="recentProjectIcon" type="github" />
                                         <a href={element.url} className="recentProjectLink">{element.title}</a>
                                       </div>
                            })
                        }
                        </div>
                    </Panel>
                </Collapse>
            </div>
            <div className='gud-diag-options_rendering_options'>
                <img loading="lazy" alt='golang code transformation' aria-label='golang code transformation' src={goBoard} style={{width:"100%"}}/>
                <p>
                    In a nutshell, turn your GoLang project's code into a UML class diagram. We want to simplify the way we see our projects, and with dumels.com, we think we can move towards our goal.
                </p>
                <p>
                    How to use:
                </p>
                <ol>
                    <li>Type or paste the URL of your GoLang (or any public GoLang project) in github.com in the Repository URL box.</li>
                    <li>Click Generate.</li>
                </ol>
                <p>
                    That's it! Now you can let this classy guy make UML class diagram representation of your GoLang Projects. You can include only the directories you want, or use "Ignore Directories" if you need to make this tool skip over unnecessary folders. You can also display or hide methods and fields in your structures.
                </p>
            </div>
        </div>

        <Feedback
            url={"https://s.surveyplanet.com/e-zA-15FZ"}
            />
        </>
    )
};
export default DiagramOptionsPanel
