import React, {useEffect, useRef, useState} from "react";
import { useParams} from "react-router-dom";
import DiagramDisplayPanel from "./diagramDisplayPanel";
import {CreateDiagram, GetDiagramMetadata} from "../../apiUtils/diagramFetch";
import {message} from "antd";
import {Diagram} from "../../models/diagram";

const StatusSuccessful = 3;
const StatusError = 0;
const refreshingTimeMillisec = 5000;

const DiagramDisplayPanelContainer:React.FC =  (props) => {
	const { id } = useParams();
	const [isLoading, setIsLoading] = useState(true);
	let intervalID = useRef<NodeJS.Timeout>();
	const [updatedDate, setUpdatedDate] = useState(new Date());
	const [diagram, setDiagram] = useState<Diagram>({
		repo: '',
		ignoredDirectories: [],
		directories: [],
		renderingOptions: {
			title: '',
			aggregations: false,
			fields: false,
			methods: false,
			compositions: false,
			implementations: false,
			aliases: false,
			connectionLabels: false,
			notes: ''
		}
	});

	const reloadDiagramImage = () => {
		setIsLoading(true);
		CreateDiagram(diagram).then((res) => {
			regenerateImageForDiagram(res['diagramId']);
		});
	};

	const displayError = (textMessage: string) => {
		message.error({
			content: textMessage,
			duration: 5,
			key: 1,
		});
	};

	const regenerateImageForDiagram = (id: string) => {
		intervalID.current = setInterval(() => {
			let getDiagramMetadataPromise = GetDiagramMetadata(id);
			getDiagramMetadataPromise.then((response: any) => {
				if (response.data.status === StatusSuccessful || response.data.status === StatusError) {
					setIsLoading(false);
					if (intervalID.current) {
						clearInterval(intervalID.current);
					}
					if (response.data.status === StatusError) {
						displayError("There was an error reprocessing your diagram image. Try again please.");
					}
					setUpdatedDate(new Date(response.data.updated));
				}
				if (response.data.renderingOptions) {
					setDiagram({
						repo: response.data.repo,
						ignoredDirectories: response.data.ignoredDirectories,
						directories: response.data.directories,
						renderingOptions: {
							title: response.data.renderingOptions.title,
							aggregations: response.data.renderingOptions.aggregations,
							fields: response.data.renderingOptions.fields,
							methods: response.data.renderingOptions.methods,
							compositions: response.data.renderingOptions.compositions,
							implementations: response.data.renderingOptions.implementations,
							aliases: response.data.renderingOptions.aliases,
							connectionLabels: response.data.renderingOptions.connectionLabels,
							notes: response.data.renderingOptions.notes
						}
					});
				}
			});

		}, refreshingTimeMillisec)
	};

	useEffect(()=>{
		if (id) {
			let getDiagramMetadataPromise = GetDiagramMetadata(id);
			getDiagramMetadataPromise.then((response:any) => {
				if (response.data.status === StatusSuccessful || response.data.status === StatusError) {
					setIsLoading(false);
					if (response.data.status === StatusError) {
						displayError("There was an error processing your diagram image. Try again please.");
					}
					setUpdatedDate(new Date(response.data.updated));
					if (response.data.renderingOptions) {
						setDiagram({
							repo: response.data.repo,
							ignoredDirectories: response.data.ignoredDirectories,
							directories: response.data.directories,
							renderingOptions: {
								title: response.data.renderingOptions.title,
								aggregations: response.data.renderingOptions.aggregations,
								fields: response.data.renderingOptions.fields,
								methods: response.data.renderingOptions.methods,
								compositions: response.data.renderingOptions.compositions,
								implementations: response.data.renderingOptions.implementations,
								aliases: response.data.renderingOptions.aliases,
								connectionLabels: response.data.renderingOptions.connectionLabels,
								notes: response.data.renderingOptions.notes
							}
						});
					}
				} else {
					intervalID.current = setInterval(() => {
						getDiagramMetadataPromise = GetDiagramMetadata(id);
						getDiagramMetadataPromise.then((response: any) => {
							if (response.data.status === StatusSuccessful || response.data.status === StatusError) {
								setIsLoading(false);
								if (intervalID.current) {
									clearInterval(intervalID.current);
								}
								if (response.data.status === StatusError) {
									displayError("There was an error processing your diagram image. Try again please.");
								}
								setUpdatedDate(new Date(response.data.updated));
							}
							if(response.data.renderingOptions) {
								setDiagram({
									repo: response.data.repo,
									ignoredDirectories: response.data.ignoredDirectories,
									directories: response.data.directories,
									renderingOptions: {
										title: response.data.renderingOptions.title,
										aggregations: response.data.renderingOptions.aggregations,
										fields: response.data.renderingOptions.fields,
										methods: response.data.renderingOptions.methods,
										compositions: response.data.renderingOptions.compositions,
										implementations: response.data.renderingOptions.implementations,
										aliases: response.data.renderingOptions.aliases,
										connectionLabels: response.data.renderingOptions.connectionLabels,
										notes: response.data.renderingOptions.notes
									}
								});
							}
						});

					}, 5000);

				}

			});


		}
	},[id]);
	return (
		<DiagramDisplayPanel diagramId={id} isLoading={isLoading} reloadDiagramImage={reloadDiagramImage} updatedDate={updatedDate} renderingOptions={diagram.renderingOptions}/>
	)
};
export default DiagramDisplayPanelContainer;