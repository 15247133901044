import { Diagram } from "../models/diagram";

export interface GitUmlDiagramWebResponse {
	diagramId: string
	success: boolean
}

//@TODO this method needs to be tested by mocking the function that is going to be used to do the actual apiUtils call
export function CreateDiagram(diagram: Diagram): Promise<any> {
	const options = {
		method: 'POST',
		body: JSON.stringify(diagram),
		headers: {
			'Content-Type': 'application/json'
		}
	};
	return fetch('/api/v1/diagram', options).then(res => res.json());
}

export function GetDiagramMetadata(diagramID: string): Promise<any> {
	const options = {
		method: 'GET',
		headers: {
			'Content-Type': 'application/json',
			'Accept': 'application/json'
		}
	};
	return fetch('/api/v1/diagram/'+diagramID, options).then(res => res.json());
}