import React from 'react';
import DiagramOptionsPanel from "./diagramOptionsPanel";
import {CreateDiagram} from "../../apiUtils/diagramFetch";
import { Diagram } from '../../models/diagram';
import {useHistory, useParams} from 'react-router-dom';
import { message } from 'antd';
import {RenderingOptions} from "../../models/renderingOptions";
import {useLocalStorage} from "react-use";
import {RecentProject} from "../../models/recentProject";
import {useFlags} from "../../context/FlagsProvider";

interface DiagramOptionsPanelContainerProps {
    repoUrl: string;
    ignoredDirectories: string;
    directories: string;
}

const DiagramOptionsPanelContainer:React.FC<DiagramOptionsPanelContainerProps> =  (props) => {
    let history = useHistory();
    const { repo } =  useParams();
    const [projects, setProjects] = useLocalStorage<RecentProject[]>('dumels-recents');
    const repoRenderingOptionsString = repo?atob(repo):"";
    let repoUrl = "";
    let repoRenderingOptions: RenderingOptions;
    try {
        repoRenderingOptions = JSON.parse(repoRenderingOptionsString);
        repoUrl = repoRenderingOptions.title;
    } catch (e) {
        repoUrl = props.repoUrl;
        repoRenderingOptions = {
            aggregations: false,
            aliases: false,
            compositions: false,
            connectionLabels: false,
            fields: true,
            implementations: false,
            methods: true,
            notes: "",
            title: ""

        }
    }
    function navigateToDiagramPage(response:any) {
        history.push('diagram/' + response["diagramId"]);
    }

    const onContinue = (diagram: Diagram) => {
        CreateDiagram(diagram)
            .then((res) => {
                if (res["success"] && res["diagramId"] !== "") {
                    let newProjectsState = new Array<RecentProject>();
                    const newProject = {
                        title: diagram.repo,
                        url:'diagram/' + res["diagramId"],
                    };
                    if (projects) {
                        newProjectsState = newProjectsState.concat(newProject,projects);
                    } else {
                        newProjectsState = [newProject];
                    }
                    setProjects(newProjectsState.slice(0,5));
                    navigateToDiagramPage(res);
                } else {
                        message.error({
                            content: res["error"],
                            duration: 10,
                            key: 1,
                        });
                }
            })
    };
    return (
        <DiagramOptionsPanel
            repoUrl={repoUrl || repoRenderingOptions.title || ""}
            ignoredDirectories={props.ignoredDirectories}
            directories={props.directories}
            isIncludedFieldsShown={repoRenderingOptions!.fields}
            isIncludedMethodsShown={repoRenderingOptions!.methods}
            onContinue={onContinue}
            flags={useFlags()}
        />
    )
};
export default DiagramOptionsPanelContainer