import React, {useState} from "react";
import {Button, Icon, Spin, Tag} from "antd";

// @ts-ignore
import { MapInteractionCSS } from 'react-map-interaction';

import {RenderingOptions} from "../../models/renderingOptions";
import {Link} from "react-router-dom";
import Feedback from "../feedback/feedback";

import "./diagramDisplayPanel.scss";

interface DiagramDisplayPanelProps {
	diagramId?: string
	isLoading: boolean
	reloadDiagramImage: () => void
	updatedDate: Date
	renderingOptions: RenderingOptions
}

const DefaultScale = 0.2;
const DefaultTranslation = {x:0, y:0};
const apiEndpoint = "/api/v1/diagram/";
const DiagramDisplayPanel:React.FC<DiagramDisplayPanelProps> = (props) => {
	const [scaleValue, setScaleValue] = useState(DefaultScale);
	const [translation, setTranslation] = useState(DefaultTranslation);
	const [fullScreenMapValue, setFullScreenMapValue] = useState<string>("");
	const [foldMenuIcon, setfoldMenuIcon] = useState<string>("menu-fold");
	const [displayMetadataSection, setDisplayMetadataSectionVisible] = useState<string>("");
	interface buttonProps {
		buttonText: string
	}
	interface mapValues {
		scale:number,
		translation: any
	}
	const mapResizeButton = (props:buttonProps) => {
		return (
			<Icon type={props.buttonText} data-testid={props.buttonText}/>
		)
	};
	const onMapChange = (value: mapValues) => {
		setScaleValue(value.scale);
		setTranslation(value.translation);
	};
	const resetMapSize = () => {
		setScaleValue(DefaultScale);
		setTranslation(DefaultTranslation);
	};

	function resetMapViewerSize() {
		if(fullScreenMapValue) {
			setFullScreenMapValue("");
			setfoldMenuIcon("menu-fold");
			setDisplayMetadataSectionVisible("");
		} else {
			setFullScreenMapValue("96%");
			setfoldMenuIcon("menu-unfold");
			setDisplayMetadataSectionVisible("none");
		}
	}

	return (
		<div className="diagramDisplayPanel">
			<div className="metadataSection" data-testid="metadataSection"
				style={
					{
						display: displayMetadataSection,
					}
				}
			>
				<div className='metadataGroup'>
					<Link to={"/"+btoa(JSON.stringify(props.renderingOptions))}><Button className="backButton" type="primary" data-testid="goBackButton" ghost><Icon type="left" />Go Back</Button></Link>
					<h1 className="renderingOptionsTitle" data-testid="renderingOptionsHeading">Rendering Options for:</h1>
					<span className="diagramTitle" data-testid='diagramTitle'>{props.renderingOptions.title+'\n'}</span><br/>

					{ !props.isLoading &&
					<>
                        <h2 className="optionsOverDiagram">Options applied:</h2>
	                    <p>
							{props.renderingOptions.fields && <span data-testid='diagramIsActiveFieldsApplied'><Tag color={props.renderingOptions.fields?'blue':'red'}>fields</Tag></span>}
							{props.renderingOptions.methods && <span data-testid='diagramIsActiveMethodsApplied'><Tag color={props.renderingOptions.methods?'blue':'red'}>methods</Tag></span>}
	                    </p>
						<h2 className="optionsOverDiagram">Options avoided:</h2>
						<p>
							{!props.renderingOptions.fields && <span data-testid='diagramIsActiveFieldsAvoided'><Tag color={props.renderingOptions.fields?'blue':'red'}>fields</Tag></span>}
							{!props.renderingOptions.methods && <span data-testid='diagramIsActiveMethodsAvoided'><Tag color={props.renderingOptions.methods?'blue':'red'}>methods</Tag></span>}
						</p>
                    </>
						}
				</div>
				<div className='metadataGroup'>
					<h2 className="optionsOverDiagram" data-testid="renderingOptionsRefreshing">Last time generated:</h2>
					<p>{props.updatedDate.toLocaleString()}</p>
					{!props.isLoading &&
					<Button style={{
						padding: 0
					}} className="refreshButton" type="primary" data-testid="regenerateNowButton"
					        onClick={()=>props.reloadDiagramImage()}>Regenerate</Button>
					}
				</div>
			</div>
			<div style={{
				padding: "0.2em",
				width: "3em"
			}}><Button type="dashed" style={{
				top: "45%",
				position: "relative",
				height: "4em"
			}} icon={foldMenuIcon} onClick={resetMapViewerSize} data-testid="fullScreenMapButton"/></div>
			<div className="mapSection"
				style={{
					width: fullScreenMapValue
				}}
			>
				<Spin style={{position:"absolute", top:"50%", left:"60%"}} spinning={props.isLoading} tip="...loading" data-testid="imageDisplayLoading"/>
				{!props.isLoading &&
					<>
		                <MapInteractionCSS
		                    showControls
		                    scale={scaleValue}
		                    translation={translation}
		                    plusBtnContents={mapResizeButton({buttonText: "plus"})}
		                    plusBtnClass="mapButton"
		                    minusBtnContents={mapResizeButton({buttonText: "minus"})}
		                    minusBtnClass="mapButton"
		                    onChange={onMapChange}
		                >
		                    <img src={apiEndpoint + props.diagramId + '?' + props.updatedDate.toString()} data-testid="diagramImage"
		                         alt="Diagram Map"/>
		                </MapInteractionCSS>

						<Button type="primary" icon="undo" style={{position:'absolute' , bottom:'3%', right:'1.5%'}} onClick={resetMapSize} data-testid="resizeMapButton">Reset</Button>
				</>
                }
			</div>
			<Feedback
				url={"https://s.surveyplanet.com/N956Z0X1j"}
			/>
		</div>
	)
};
export default DiagramDisplayPanel;