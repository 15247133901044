// FlagsProvider.js
import React from 'react';
import { useEffect, useState } from "react";
import { firebaseDumels } from './firebase';
import semver from 'semver';
import {isDeepEqual} from "react-use/lib/util";
export const FlagsContext = React.createContext({
    loginFeatureEnabled: false,
});
// Only for development
// remoteConfig.settings = {
//     minimumFetchIntervalMillis: 3600000,
// };
const FlagsProvider = ({ defaults, children }) => {
    const remoteConfig = firebaseDumels.remoteConfig();
    const [flags, setFlags] = useState(defaults);
    useEffect(() => {
        remoteConfig.defaultConfig = defaults;
        remoteConfig
            .fetchAndActivate()
            .then(activated => {
                if (!activated) console.log('not activated');
                return remoteConfig.getAll();
            })
            .then(remoteFlags => {
                const newFlags = {
                    ...flags,
                };
                for (const [key, config] of Object.entries(remoteFlags)) {
                    const appVer = process.env.REACT_APP_VERSION;
                    newFlags[key] =
                        semver.satisfies(appVer, config.asString());
                }
                if(!isDeepEqual(newFlags, flags)){
                    setFlags(newFlags);
                }
            })
            .catch(error => console.error(error));
    }, [defaults, flags, remoteConfig]);
    return (
        <FlagsContext.Provider value={flags}>
            {children}
        </FlagsContext.Provider>
    );
};

export const useFlags = () => {
    const context = React.useContext(FlagsContext);
    return context;
};

export default FlagsProvider;